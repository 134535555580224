<template>
  <NuxtImg provider="kontentAiProvider" :src="imageUrl" densities="x1" class="pt-image"></NuxtImg>
</template>
<script lang="ts" setup>
const props = defineProps({
  value: {
    type: Object,
    required: true
  }
})

//@ts-ignore
const imageUrl = props.value.asset.url
</script>
