<template>
  <PortableText
    v-if="portableTextJson.length > 0"
    :value="portableTextJson"
    :components="components"
    :on-missing-component="onMissingComponent"
  />
</template>
<script setup lang="ts">
import PtComponent from './PtComponent.vue'
import PtImage from './PtImage.vue'
import PtTable from './PtTable.vue'
import PtItemLink from './PtItemLink.vue'
import forEach from 'lodash/forEach'
import { isEmpty } from '../../utils/kontent-helper'
import {
  type PortableTextObject,
  transformToPortableText,
  nodeParse
} from '@kontent-ai/rich-text-resolver'
import {
  PortableText,
  type PortableTextMarkComponent,
  type PortableTextTypeComponent,
  type PortableTextVueComponents
} from '@portabletext/vue'
import PtSuperscript from './PtSuperscript.vue'

const props = defineProps<{
  value: string | undefined
  portableText?: PortableTextObject[] | undefined
}>()

const config = useRuntimeConfig()
const portableTextJson = ref<PortableTextObject[]>([])
const portableTextJsonModifiers = [modifyPortableTextBlockQuotes]

if (props.value && !isEmpty(props.value)) {
  try {
    portableTextJson.value = transformToPortableText(nodeParse(props.value))
    forEach(
      portableTextJsonModifiers,
      (modifier) => (portableTextJson.value = modifier(portableTextJson.value))
    )
  } catch (e) {
    console.error('Error parsing value', props.value)
    console.error('Error parsing JSON', e)
  }
}

if (props.portableText && props.portableText.length > 0) {
  portableTextJson.value = props.portableText
  forEach(
    portableTextJsonModifiers,
    (modifier) => (portableTextJson.value = modifier(portableTextJson.value))
  )
}

const components = {
  types: {
    component: PtComponent,
    image: PtImage,
    table: PtTable
  } as PortableTextTypeComponent,
  marks: {
    internalLink: PtItemLink,
    link: PtItemLink,
    sup: PtSuperscript
  } as PortableTextMarkComponent
} as PortableTextVueComponents

const onMissingComponent = (message: string, options: any) => {
  if (config.public.isProduction) return
  console.log(message, {
    // eg `someUnknownType`
    type: options.type,
    // 'block' | 'mark' | 'blockStyle' | 'listStyle' | 'listItemStyle'
    nodeType: options.nodeType
  })
}
</script>
