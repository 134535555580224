import {
  LazyAccomplishmentItem,
  LazyAccordionItem,
  LazyAward,
  LazyClient,
  LazyQuickFact,
  LazySplitContentBasicContentBlock,
  LazySplitContentBlock,
  LazyAccomplishmentSection,
  LazySplitContentAccordionList,
  LazyTeamList,
  LazyHomeHero,
  LazyHeroSlide,
  LazyInteriorHero,
  LazyFeaturedInteriorPageBlock,
  LazyFeaturedPeoplePageBlock,
  LazyFeaturedCapabilitesPageBlock,
  LazyInsightList,
  LazyContentBlock,
  LazyAwardList,
  LazyCapabilityList,
  LazyLocationList,
  LazyClientList,
  LazyQuickFactList,
  LazyOpenPositionsLists,
  LazyFeaturedContent,
  LazyFeaturedInsightPageBlock,
  LazyTabContentBlock,
  LazyPeopleSearchBlock,
  LazyManagementList,
  LazyContentHorizontalLine,
  LazyContentImageWrap,
  LazyReferenceList,
  LazyTable,
  LazyTableRow,
  LazyTableCell,
  LazyCallToAction,
  LazyIFrame,
  LazyPageListPage,
  LazyMusicStreamingCalculatorBlock,
  LazyContentAssetsWithLinks,
  LazyImageBlock,
  LazyCardModal,
  LazyCardList,
  LazyVenturesInsightSearchBlock,
} from '#components'
import { contentTypes } from '~/models'

// Define the mapping of content types to their respective components
const components: { [key: string]: any } = {
  // Blocks
  [contentTypes.accomplishment_item.codename]: LazyAccomplishmentItem,
  [contentTypes.accordion_item.codename]: LazyAccordionItem,
  [contentTypes.award.codename]: LazyAward,
  [contentTypes.client.codename]: LazyClient,
  [contentTypes.quick_fact.codename]: LazyQuickFact,
  [contentTypes.content_block.codename]: LazyContentBlock,
  [contentTypes.split_content__basic_content_block.codename]: LazySplitContentBasicContentBlock,
  [contentTypes.split_content_block.codename]: LazySplitContentBlock,
  [contentTypes.featured_content_block.codename]: LazyFeaturedContent,
  [contentTypes.tab_content_block.codename]: LazyTabContentBlock,
  [contentTypes.people_search_block.codename]: LazyPeopleSearchBlock,
  [contentTypes._content___horizontal_line.codename]: LazyContentHorizontalLine,
  [contentTypes._content___image_wrap.codename]: LazyContentImageWrap,
  [contentTypes._iframe.codename]: LazyIFrame,
  [contentTypes.music_streaming_calculator.codename]: LazyMusicStreamingCalculatorBlock,
  [contentTypes._content___asset_w__link.codename]: LazyContentAssetsWithLinks,
  [contentTypes._image_block.codename]: LazyImageBlock,
  [contentTypes.ventures_insight_search_block.codename]: LazyVenturesInsightSearchBlock,

  // Featured
  [contentTypes.interior_page.codename]: LazyFeaturedInteriorPageBlock,
  [contentTypes.people_page.codename]: LazyFeaturedPeoplePageBlock,
  [contentTypes.capabilities_page.codename]: LazyFeaturedCapabilitesPageBlock,
  [contentTypes.insight_page.codename]: LazyFeaturedInsightPageBlock,
  [contentTypes._card_modal.codename]: LazyCardModal,

  // Collections
  [contentTypes.accomplishment_section.codename]: LazyAccomplishmentSection,
  [contentTypes.accordion_list.codename]: LazySplitContentAccordionList,
  [contentTypes.team_list.codename]: LazyTeamList,
  [contentTypes.location_list.codename]: LazyLocationList,
  [contentTypes.insight_list_page.codename]: LazyInsightList,
  [contentTypes.award_list.codename]: LazyAwardList,
  [contentTypes.capability_list.codename]: LazyCapabilityList,
  [contentTypes.client_list.codename]: LazyClientList,
  [contentTypes.open_positions_list.codename]: LazyOpenPositionsLists,
  [contentTypes.quick_fact_list.codename]: LazyQuickFactList,
  [contentTypes._management_list.codename]: LazyManagementList,
  [contentTypes._reference_list.codename]: LazyReferenceList,
  [contentTypes.table.codename]: LazyTable,
  [contentTypes.table_row.codename]: LazyTableRow,
  [contentTypes.table_cell.codename]: LazyTableCell,
  [contentTypes.card_list.codename]: LazyCardList,
  
  // hero
  [contentTypes.home_hero.codename]: LazyHomeHero,
  [contentTypes.home_hero_slide.codename]: LazyHeroSlide,
  [contentTypes.interior_hero.codename]: LazyInteriorHero,
  [contentTypes.interior_hero_slide.codename]: LazyHeroSlide,
  [contentTypes.call_to_action.codename]: LazyCallToAction,

  // // Rich Text
  // [contentTypes._navigation_link.codename]: LazyNavigationLink,
  // [contentTypes.anchor_tag.codename]: LazyAnchorTag,

  // Shared
  [contentTypes._page_list.codename]: LazyPageListPage
}

export default function (type: string) {
  return components[type]
}
