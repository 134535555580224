<template>
    <client-only>
        <template v-if="isPageReference">
            <slot />
        </template>
        <template v-else-if="isTextColor">
            <span :style="`color: var(--theme-${textColorCode})`">{{ text }}</span>
        </template>
        <template v-else-if="isBlockQuote">
            <ContentBlockQuote :text="text" />
        </template>
        <template v-else-if="isTextUnderline">
            <span style="text-decoration: underline">{{ text }}</span>
        </template>

        <template v-else>
            <NuxtLink v-if="!href.startsWith('#')" :to="href" :target="linkTarget">
                {{ text }}
            </NuxtLink>
            <a v-else data-bs-toggle="tooltip" @click="scrollToElement(href, 130)" class="btn btn-link">
                {{ text }}
            </a>
        </template>
    </client-only>
</template>
<script lang="ts" setup>
const { scrollToElement } = useSmoothScroll()
const siteStore = useSiteStore()
const pageStore = usePageStore()
const props = defineProps<{
    text: string,
    value: any,
}>()

const href = ref<string>('')
const itemId = ref<string>('')
const linkTarget = ref<string>('_self')
const isPageReference = ref<boolean>(false)
const isTextColor = ref<boolean>(false)
const textColorCode = ref<string>('')
const isTextUnderline = ref<boolean>(false)
const isBlockQuote = ref<boolean>(false)

// console.log(props.value);
if (props.value.reference?._type == 'reference') {
    itemId.value = props.value.reference._ref
    const item = pageStore.getPageReferences()[props.text.trim()] as any
    isPageReference.value = item && 'isPageReference' in item;
    pageStore.getUrlByItemId(itemId.value).then((url) => {
        if (url)
            href.value = url;
    });

    if (!isPageReference.value) {
        const textColor = siteStore.getTextColorCode(itemId.value)
        const blockQuote = siteStore.getContentQuoteBlock(itemId.value)
        if (textColor) {
            isTextColor.value = true
            textColorCode.value = textColor
        }
        else if (blockQuote) {
            isBlockQuote.value = true;
        }


        const textUnderline = siteStore.getTextUnderlineCode(itemId.value)
        if (textUnderline) {
            isTextUnderline.value = true;
        }

    }



}
else if ('data-asset-id' in props.value) {
    href.value = props.value.href
    linkTarget.value = '_blank'
}
else {
    href.value = props.value.href
    linkTarget.value = '_blank'
}
</script>
