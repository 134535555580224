<template>
  <client-only>
    <sup v-if="!hasReference">
      <a :id="`p-ref-${referenceId}`" @click="scrollToElement(`ref-${referenceId}`, 130)">
        {{ referenceText }}
      </a>
      {{ additionalText }}
    </sup>
    <sup v-else>
      <a :id="`ref-${referenceId}`" @click="scrollToElement(`p-ref-${referenceId}`, 130)">
        {{ referenceText }}
      </a>
      {{ additionalText }}
    </sup>
  </client-only>
</template>
<script lang="ts" setup>
const { scrollToElement } = useSmoothScroll()
const props = defineProps<{
  text: string
}>()

const pageStore = usePageStore()
const { referenceId, referenceText, additionalText } = useSuperscriptParser(props.text)

let hasReference = false

if (import.meta.client) {
  const reference = pageStore.getSuperscriptReference(referenceText)
  hasReference = !!reference

  if (!hasReference) {
    pageStore.addSuperscriptReference(referenceText)
  }
}
</script>
<style scoped>
sup {
  cursor: pointer;
}

a {
  color: var(--theme-color) !important;
  text-decoration: underline !important;
}
</style>
