<template>
    <component v-if="activeComponent" :is="activeComponent" :model="model" />
</template>
<script lang="ts" setup>
import type { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
const props = defineProps({
    value: {
        type: Object,
        required: true
    }
});

let activeComponent: any = null
const model = ref<any>();

let linkedItem: any = null
const pageStore = usePageStore()
const linkedItems = pageStore.linkedItems as IContentItemsContainer

const codenameIdMapState = useState<{codename:string,id:string}[]>('codenameIdMapState', () => []);

//POTENTIAL RESOLUTION OF componentTypeMapper error
// const componentTypeMapper = (type: string) => {
//   switch (type) {
//     case 'image_wrap':
//       return ImageWrapComponent; // Make sure this is imported and available
//     // Add other cases here
//     default:
//       return null;
//   }
// };

if (props.value.component._type === 'reference') {
    const codename = props.value.component._ref

    linkedItem = linkedItems[codename]
    if (linkedItem) {
        activeComponent = componentTypeMapper(linkedItem.system.type)
        if (!activeComponent) {
            console.error(`Missing Map: ${linkedItem.system.type}`)
        }
        model.value = linkedItem
        codenameIdMapState.value.push({
            codename: codename,
            id: `_${linkedItem.system.id}`
        });
    }
}
</script>
