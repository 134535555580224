export const useSuperscriptParser = (text: string) => {

  const referenceInWords = text.trim().split(/\s+/); // split superscript by a white space delimiter

  const referenceId = referenceInWords[0]?.replace(/\[|\]/g, '-') // replace [] with -
  
  const referenceText = referenceInWords.length == 1 ? text.trim() : referenceInWords[0] // gets the reference text to use on <a> tag
  
  const additionalText = referenceInWords.length == 1 ? '' : referenceInWords.slice(1, referenceInWords.length).join(' ') // gets the additional text to use outside <a> tag within <sup> tag

  return {
    referenceId,
    referenceText,
    additionalText
  }
}